
<script>
import { Line } from 'vue-chartjs'

export default {
  name: 'LineChartElement',
  extends: Line,
  props: {
    data: {
      type: Object,
      default: null
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    showTooltipTitle: {
      type: Boolean,
      default: true
    },
    tiksCollor: {
      type: String,
      default: '#333'
    },
    tiksSize: {
      type: Number,
      default: 12
    },
    showGridLines: {
      type: Boolean,
      default: true
    },
    minimalYticks: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      options: {
        legend: {
          position: 'top',
          display: this.showLegend
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: this.minimalYticks,
              fontColor: this.tiksCollor,
              fontSize: this.tiksSize,
            },
            gridLines: {
              zeroLineColor: this.showGridLines ? '#333' : 'transparent'
            }
          }],
          xAxes: [{
            ticks: {
              fontColor: this.tiksCollor,
              fontSize: this.tiksSize,
            },
            gridLines: {
              zeroLineColor: this.showGridLines ? '#333' : 'transparent'
            }
          }]
        },
        tooltips: {
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: this.showTooltipTitle ? function(tooltipItem) { return tooltipItem[0].label } : function() {}
          }
        }
      }
    }
  },
  watch: {
    data: {
      handler() {
        this.renderChart(this.filteredData, this.options);
      },
      deep: true
    }
  },
  mounted () {
    // this.renderChart(this.data, this.options)
    this.renderChart(this.filteredData, this.options)
  },
  computed: {
    // filteredData() {
    //  let result = this.data.datasets.filter(item => item.label !=='telegram')
    //  return { ...this.data, datasets :result }
    // }
    filteredData() {
      let result = this.data.datasets.filter(item => item.statistics)
      const output = { ...this.data, datasets :result }
      return output
    }
   },
  methods: {
    update() {
      this.$data._chart.update()
    }
  }
}
</script>
